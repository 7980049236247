import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import { colors } from '../style/colors';
import { flex } from '../style/style-helper';
import { FillSpace } from '../Layouts';

const useStyles = makeStyles((theme) => ({
  frame: {
    ...flex.col(),
    '& a': {
      textDecoration: 'none'
    },
    maxWidth: '24rem',
    width: '80%',
    margin: '1.5rem',
    backgroundColor: colors.white,
    boxShadow:
      '0px 6px 10px -3px rgba(0,0,0,0.15), 0px 10px 14px 1px rgba(0,0,0,0.10), 0px 4px 18px 3px rgba(0,0,0,0.06)',
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden'
  },
  content: {
    ...flex.col(),
    width: '80%'
  }
}));

function StackEditor({ item, onSave, onClose, onDelete, showDelete }) {
  const classes = useStyles();
  const [title, setTitle] = useState(item.title);
  const [links, setLinks] = useState(item.links.join('\n'));

  function onTitleChange(event) {
    setTitle(event.target.value);
  }

  function onLinksChange(event) {
    setLinks(event.target.value);
  }
  function onClickSave() {
    const newLinks = links.split('\n');
    const updated = { ...item, title, links: newLinks };
    onSave(updated);
  }

  const onClickDelete = () => onDelete(item);
  const saveLabel = showDelete ? 'Update' : 'Create'
  return (
    <>
      <CardContent classes={{ root: classes.content }}>
        <TextField label="title" value={title} onChange={onTitleChange} />
        <TextField label="links" value={links} onChange={onLinksChange} multiline />
      </CardContent>
      <CardActions>
        <Button onClick={onClickSave}>{saveLabel}</Button>
        <Button onClick={onClose}>Cancel</Button>
        <FillSpace />
        {showDelete && <Button onClick={onClickDelete}>Delete</Button>}
      </CardActions>
    </>
  );
}

StackEditor.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired
};

export default StackEditor;
