import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { FiLogOut, FiPlusCircle } from 'react-icons/fi';
import { Loading } from './Loading';
import { FillSpace } from './Layouts';
import { border } from './style/style-helper';
import Logo from './Logo';
import Auth from '../services/auth';

const useStyles = makeStyles((theme) => ({
  noShadowTopBorder: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderTop: border('tailwind.blue', 500, 2),
    padding: theme.spacing(0.5, 0)
  },
  noLeftMarginPadding: {
    marginLeft: 0,
    paddingLeft: 0
  },
  icon: {
    height: 24,
    width: 24
  }
}));

function AppBar({ isLoading, onCreateClick }) {
  const classes = useStyles();

  function onSignOutClick() {
    Auth.signOut();
  }
  return (
    <MuiAppBar position="relative" classes={{ root: classes.noShadowTopBorder }}>
      <Toolbar classes={{ root: classes.noLeftMarginPadding }}>
        <Logo />
        <Loading isLoading={isLoading} />
        <FillSpace />
        <IconButton edge="end" aria-label="Create new stack" classes={{ label: classes.icon }} onClick={onCreateClick}>
          <FiPlusCircle color="#818FB3" />
        </IconButton>
        <IconButton edge="end" aria-label="Sign Out" classes={{ label: classes.icon }} onClick={onSignOutClick}>
          <FiLogOut color="#818FB3" />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
}

AppBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCreateClick: PropTypes.func.isRequired
};

export default AppBar;
