import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import theme from './components/style/theme';
import AppBar from './components/AppBar';
import NoResults from './components/NoResults';
import SearchResults from './components/SearchResults';
import { Content, FixedBar, ScreenHeightFlexColumn } from './components/Layouts';

import { colors } from './components/style/colors';
import ApiClient from './services/api-client';
import Stack from './components/package/Stack';

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: colors.tailwind.gray[400]
  }
}));

function ContentSwitch({ results, creating, isLoading, onUpdate, onCreate, onCloseCreate, onDelete }) {
  if (creating) return <Stack item={{ links: [], title: '' }} onSave={onCreate} isNew onCloseEditor={onCloseCreate} />;

  if (!results || isLoading) return null;
  if (results.length === 0) return <NoResults />;

  return <SearchResults items={results} onUpdate={onUpdate} onDelete={onDelete} />;
}
ContentSwitch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(PropTypes.any).isRequired
};

function MainScreen({ user, config }) {
  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  async function fetchStacks() {
    setLoading(true);
    const stacks = await ApiClient.fetchStacks(user.credentials, config);
    setLoading(false);
    setResults(stacks);
  }

  async function onDeleteStack(stack) {
    setLoading(true);
    try {
      await ApiClient.deleteStack(user.credentials, config, stack);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(true);
    await fetchStacks();
  }

  async function onUpdateStack(stack) {
    setLoading(true);
    try {
      await ApiClient.updateStack(user.credentials, config, stack);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(true);
    await fetchStacks();
  }

  async function onCloseCreate() {
    setCreating(false);
  }

  async function onCreateStack(stack) {
    setLoading(true);
    try {
      await ApiClient.createStack(user.credentials, config, stack);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(true);
    onCloseCreate();
    await fetchStacks();
  }

  const onCreateClick = () => setCreating(true);

  useEffect(() => {
    fetchStacks();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, config]);

  return (
    <ThemeProvider theme={theme}>
      <ScreenHeightFlexColumn>
        <FixedBar>
          <AppBar isLoading={loading} onCreateClick={onCreateClick} />
        </FixedBar>
        <Divider classes={{ root: classes.divider }} />
        <Content>
          <ContentSwitch
            creating={creating}
            results={results}
            isLoading={loading}
            onUpdate={onUpdateStack}
            onCreate={onCreateStack}
            onCloseCreate={onCloseCreate}
            onDelete={onDeleteStack}
          />
        </Content>
      </ScreenHeightFlexColumn>
      ;
    </ThemeProvider>
  );
}

export default MainScreen;
