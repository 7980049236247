const routes = {
  signIn: '/signin',
  signOut: '/signout',
  authenticatedCallback: '/authenticated',
  signedOutCallback: '/signedout',
  home: '/'
};

const host = process.env.REACT_APP_HOST || 'http://localhost:3000';

const awsConfig = {
  Analytics: {
    disabled: true
  },
  Auth: {
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${host}${routes.authenticatedCallback}`,
      redirectSignOut: `${host}${routes.signedOutCallback}`,

      responseType: 'code',

      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    },
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
  }
};

const makeCognitoUrl = type =>
  `https://${awsConfig.Auth.oauth.domain}/${type}?redirect_uri=${awsConfig.Auth.oauth.redirectSignIn}&response_type=${
    awsConfig.Auth.oauth.responseType
  }&client_id=${awsConfig.Auth.userPoolWebClientId}`;

const signInUrl = makeCognitoUrl('login');

const signUpUrl = makeCognitoUrl('signup');



const apiHost = process.env.REACT_APP_API_HOST;

const config = {
  apiHost,
  apiBaseUrl: `https://${apiHost}`,
  routes,
  signInUrl,
  signUpUrl,
  awsConfig
};

export default config;
