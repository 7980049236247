import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from './style/colors';
import { flex } from './style/style-helper';

const useStyles = makeStyles(() => ({
  screenHeightFlexColumn: {
    ...flex.col(),
    height: '100vh',
    overflow: 'hidden'
  },
  barWrapper: {
    flex: 'none',
    padding: 0
  },
  barFrame: {
    ...flex.row('center', 'flex-start')
  },
  flexFill: {
    flex: '1 1 auto',
    overflowY: 'auto',
    backgroundColor: colors.tailwind.gray[100]
  },
  fullWidthTopBorderCenterContent: {
    ...flex.row('center'),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  centerContent: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  grow: {
    flexGrow: 1
  }
}));

export function FixedBar({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.barWrapper}>
      <div className={classes.barFrame}>{children}</div>
    </div>
  );
}

export function Content({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.flexFill}>
      <div className={classes.fullWidthTopBorderCenterContent}>
        <div className={classes.centerContent}>{children}</div>
      </div>
    </div>
  );
}

export function ScreenHeightFlexColumn({ children }) {
  const classes = useStyles();
  return <div className={classes.screenHeightFlexColumn}>{children}</div>;
}

export function FillSpace() {
  const classes = useStyles();
  return <div className={classes.grow} />;
}
