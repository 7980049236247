export const typeColors = ['#465060', '#746c70', '#6b6c6d', '#6a6b6b', '#5e5754', '#5e5d5d'];

export const colors = {
  transparent: 'transparent',

  black: '#000',
  white: '#fff',
  custom: {
    blue2: {
      100: '#DCEFFF',
      200: '#B9DDFF',
      300: '#96C8FF',
      400: '#7CB5FF',
      500: '#5196FF',
      600: '#3B74DB',
      700: '#2855B7',
      800: '#193B93',
      900: '#0F287A',
      transparent: {
        100: 'rgba(81, 150, 255, 0.08)',
        200: 'rgba(81, 150, 255, 0.16)',
        300: 'rgba(81, 150, 255, 0.24)',
        400: 'rgba(81, 150, 255, 0.32)',
        500: 'rgba(81, 150, 255, 0.4)',
        600: 'rgba(81, 150, 255, 0.48)'
      }
    },
    blue3: {
      100: '#DEEBFF',
      200: '#BED6FF',
      300: '#9EBEFF',
      400: '#86ABFF',
      500: '#5E8BFF',
      600: '#446ADB',
      700: '#2F4DB7',
      800: '#1D3493',
      900: '#12237A',
      transparent: {
        100: 'rgba(94, 139, 255, 0.08)',
        200: 'rgba(94, 139, 255, 0.16)',
        300: 'rgba(94, 139, 255, 0.24)',
        400: 'rgba(94, 139, 255, 0.32)',
        500: 'rgba(94, 139, 255, 0.4)',
        600: 'rgba(94, 139, 255, 0.48)'
      }
    },
    blue4: {
      100: '#DEF0FF',
      200: '#BDDFFF',
      300: '#9CCCFF',
      400: '#83BAFF',
      500: '#5B9DFF',
      600: '#4279DB',
      700: '#2D5AB7',
      800: '#1D3E93',
      900: '#112A7A',
      transparent: {
        100: 'rgba(91, 157, 255, 0.08)',
        200: 'rgba(91, 157, 255, 0.16)',
        300: 'rgba(91, 157, 255, 0.24)',
        400: 'rgba(91, 157, 255, 0.32)',
        500: 'rgba(91, 157, 255, 0.4)',
        600: 'rgba(91, 157, 255, 0.48)'
      }
    },
    blue5: {
      100: '#D8F2FD',
      200: '#B1E2FC',
      300: '#89CBF8',
      400: '#6BB4F1',
      500: '#3C92E8',
      600: '#2B71C7',
      700: '#1E54A7',
      800: '#133B86',
      900: '#0B296F',
      transparent: {
        100: 'rgba(60, 146, 232, 0.08)',
        200: 'rgba(60, 146, 232, 0.16)',
        300: 'rgba(60, 146, 232, 0.24)',
        400: 'rgba(60, 146, 232, 0.32)',
        500: 'rgba(60, 146, 232, 0.4)',
        600: 'rgba(60, 146, 232, 0.48)'
      }
    },
    lightBlue: {
      100: '#E2F6FE',
      200: '#C6ECFE',
      300: '#A9DDFD',
      400: '#93CEFB',
      500: '#70B7F9',
      600: '#518FD6',
      700: '#386BB3',
      800: '#234B90',
      900: '#153477',
      transparent: {
        100: 'rgba(112, 183, 249, 0.08)',
        200: 'rgba(112, 183, 249, 0.16)',
        300: 'rgba(112, 183, 249, 0.24)',
        400: 'rgba(112, 183, 249, 0.32)',
        500: 'rgba(112, 183, 249, 0.4)',
        600: 'rgba(112, 183, 249, 0.48)'
      }
    },
    brown: {
      100: '#FBF9F5',
      200: '#F7F2EB',
      300: '#E9E0D8',
      400: '#D3C8C0',
      500: '#B7A9A1',
      600: '#9D8175',
      700: '#835D51',
      800: '#6A3D33',
      900: '#57251E',
      transparent: {
        100: 'rgba(183, 169, 161, 0.08)',
        200: 'rgba(183, 169, 161, 0.16)',
        300: 'rgba(183, 169, 161, 0.24)',
        400: 'rgba(183, 169, 161, 0.32)',
        500: 'rgba(183, 169, 161, 0.4)',
        600: 'rgba(183, 169, 161, 0.48)'
      }
    },
    purpleGray: {
      100: '#F4F3F8',
      200: '#E9E8F2',
      300: '#CCCBD8',
      400: '#A5A4B2',
      500: '#73727F',
      600: '#55536D',
      700: '#3B395B',
      800: '#262449',
      900: '#17153C',
      transparent: {
        100: 'rgba(115, 114, 127, 0.08)',
        200: 'rgba(115, 114, 127, 0.16)',
        300: 'rgba(115, 114, 127, 0.24)',
        400: 'rgba(115, 114, 127, 0.32)',
        500: 'rgba(115, 114, 127, 0.4)',
        600: 'rgba(115, 114, 127, 0.48)'
      }
    },
    pewter: {
      100: '#F9F9F9',
      200: '#F3F3F3',
      300: '#DCDCDC',
      400: '#BABABA',
      500: '#8C8C8C',
      600: '#786667',
      700: '#64464B',
      800: '#512C36',
      900: '#431A28',
      transparent: {
        100: 'rgba(140, 140, 140, 0.08)',
        200: 'rgba(140, 140, 140, 0.16)',
        300: 'rgba(140, 140, 140, 0.24)',
        400: 'rgba(140, 140, 140, 0.32)',
        500: 'rgba(140, 140, 140, 0.4)',
        600: 'rgba(140, 140, 140, 0.48)'
      }
    },
    nickelGray: {
      100: '#F4F7F9',
      200: '#EBEFF3',
      300: '#D1D6DC',
      400: '#AEB3BA',
      500: '#80858C',
      600: '#5D6778',
      700: '#404C64',
      800: '#283451',
      900: '#182343',
      transparent: {
        100: 'rgba(128, 133, 140, 0.08)',
        200: 'rgba(128, 133, 140, 0.16)',
        300: 'rgba(128, 133, 140, 0.24)',
        400: 'rgba(128, 133, 140, 0.32)',
        500: 'rgba(128, 133, 140, 0.4)',
        600: 'rgba(128, 133, 140, 0.48)'
      }
    },
    lighterBlueGray: {
      100: '#E9F5FD',
      200: '#D4E9FC',
      300: '#BCD9F7',
      400: '#A8C8F0',
      500: '#8AB0E6',
      600: '#6488C5',
      700: '#4565A5',
      800: '#2C4585',
      900: '#1A2F6E',
      transparent: {
        100: 'rgba(138, 176, 230, 0.08)',
        200: 'rgba(138, 176, 230, 0.16)',
        300: 'rgba(138, 176, 230, 0.24)',
        400: 'rgba(138, 176, 230, 0.32)',
        500: 'rgba(138, 176, 230, 0.4)',
        600: 'rgba(138, 176, 230, 0.48)'
      }
    },
    blueGray: {
      100: '#EDF2FB',
      200: '#DBE5F7',
      300: '#C1CEE8',
      400: '#A5B3D1',
      500: '#818FB3',
      600: '#5E6D99',
      700: '#414F80',
      800: '#293567',
      900: '#182255',
      transparent: {
        100: 'rgba(129, 143, 179, 0.08)',
        200: 'rgba(129, 143, 179, 0.16)',
        300: 'rgba(129, 143, 179, 0.24)',
        400: 'rgba(129, 143, 179, 0.32)',
        500: 'rgba(129, 143, 179, 0.4)',
        600: 'rgba(129, 143, 179, 0.48)'
      }
    },
    purple: {
      100: '#E8EBFE',
      200: '#D1D7FE',
      300: '#BAC2FD',
      400: '#A8B1FB',
      500: '#8B96F9',
      600: '#656FD6',
      700: '#464EB3',
      800: '#2C3390',
      900: '#1A2077',
      transparent: {
        100: 'rgba(139, 150, 249, 0.08)',
        200: 'rgba(139, 150, 249, 0.16)',
        300: 'rgba(139, 150, 249, 0.24)',
        400: 'rgba(139, 150, 249, 0.32)',
        500: 'rgba(139, 150, 249, 0.4)',
        600: 'rgba(139, 150, 249, 0.48)'
      }
    },
    green: {
      100: '#D3FDD5',
      200: '#A8FCB5',
      300: '#7CF89B',
      400: '#5AF18F',
      500: '#27E87E',
      600: '#1CC77A',
      700: '#13A773',
      800: '#0C8668',
      900: '#076F5F',
      transparent: {
        100: 'rgba(39, 232, 126, 0.08)',
        200: 'rgba(39, 232, 126, 0.16)',
        300: 'rgba(39, 232, 126, 0.24)',
        400: 'rgba(39, 232, 126, 0.32)',
        500: 'rgba(39, 232, 126, 0.4)',
        600: 'rgba(39, 232, 126, 0.48)'
      }
    },
    orange: {
      100: '#FFF4DA',
      200: '#FFE5B6',
      300: '#FFD391',
      400: '#FFC176',
      500: '#FFA449',
      600: '#DB8035',
      700: '#B75F24',
      800: '#934217',
      900: '#7A2E0E',
      transparent: {
        100: 'rgba(255, 164, 73, 0.08)',
        200: 'rgba(255, 164, 73, 0.16)',
        300: 'rgba(255, 164, 73, 0.24)',
        400: 'rgba(255, 164, 73, 0.32)',
        500: 'rgba(255, 164, 73, 0.4)',
        600: 'rgba(255, 164, 73, 0.48)'
      }
    },
    yellow: {
      100: '#FFFBD9',
      200: '#FFF7B3',
      300: '#FFF28D',
      400: '#FFED71',
      500: '#FFE542',
      600: '#DBC130',
      700: '#B79E21',
      800: '#937C15',
      900: '#7A650C',
      transparent: {
        100: 'rgba(255, 229, 66, 0.08)',
        200: 'rgba(255, 229, 66, 0.16)',
        300: 'rgba(255, 229, 66, 0.24)',
        400: 'rgba(255, 229, 66, 0.32)',
        500: 'rgba(255, 229, 66, 0.4)',
        600: 'rgba(255, 229, 66, 0.48)'
      }
    },
    blue: {
      100: '#D6F4FF',
      200: '#ADE5FF',
      300: '#83D2FF',
      400: '#65BFFF',
      500: '#329FFF',
      600: '#247CDB',
      700: '#195CB7',
      800: '#0F4193',
      900: '#092D7A',
      transparent: {
        100: 'rgba(50, 159, 255, 0.08)',
        200: 'rgba(50, 159, 255, 0.16)',
        300: 'rgba(50, 159, 255, 0.24)',
        400: 'rgba(50, 159, 255, 0.32)',
        500: 'rgba(50, 159, 255, 0.4)',
        600: 'rgba(50, 159, 255, 0.48)'
      }
    },
    red: {
      100: '#FFE7D7',
      200: '#FFCAAF',
      300: '#FFA687',
      400: '#FF8469',
      500: '#FF4B38',
      600: '#DB2B28',
      700: '#B71C26',
      800: '#931125',
      900: '#7A0A24',
      transparent: {
        100: 'rgba(255, 75, 56, 0.08)',
        200: 'rgba(255, 75, 56, 0.16)',
        300: 'rgba(255, 75, 56, 0.24)',
        400: 'rgba(255, 75, 56, 0.32)',
        500: 'rgba(255, 75, 56, 0.4)',
        600: 'rgba(255, 75, 56, 0.48)'
      }
    }
  },
  tailwind: {
    gray: {
      100: '#f7fafc',
      200: '#edf2f7',
      300: '#e2e8f0',
      400: '#cbd5e0',
      500: '#a0aec0',
      600: '#718096',
      700: '#4a5568',
      800: '#2d3748',
      900: '#1a202c'
    },
    red: {
      100: '#fff5f5',
      200: '#fed7d7',
      300: '#feb2b2',
      400: '#fc8181',
      500: '#f56565',
      600: '#e53e3e',
      700: '#c53030',
      800: '#9b2c2c',
      900: '#742a2a'
    },
    orange: {
      100: '#fffaf0',
      200: '#feebc8',
      300: '#fbd38d',
      400: '#f6ad55',
      500: '#ed8936',
      600: '#dd6b20',
      700: '#c05621',
      800: '#9c4221',
      900: '#7b341e'
    },
    yellow: {
      100: '#fffff0',
      200: '#fefcbf',
      300: '#faf089',
      400: '#f6e05e',
      500: '#ecc94b',
      600: '#d69e2e',
      700: '#b7791f',
      800: '#975a16',
      900: '#744210'
    },
    green: {
      100: '#f0fff4',
      200: '#c6f6d5',
      300: '#9ae6b4',
      400: '#68d391',
      500: '#48bb78',
      600: '#38a169',
      700: '#2f855a',
      800: '#276749',
      900: '#22543d'
    },
    teal: {
      100: '#e6fffa',
      200: '#b2f5ea',
      300: '#81e6d9',
      400: '#4fd1c5',
      500: '#38b2ac',
      600: '#319795',
      700: '#2c7a7b',
      800: '#285e61',
      900: '#234e52'
    },
    blue: {
      100: '#ebf8ff',
      200: '#bee3f8',
      300: '#90cdf4',
      400: '#63b3ed',
      500: '#4299e1',
      600: '#3182ce',
      700: '#2b6cb0',
      800: '#2c5282',
      900: '#2a4365'
    },
    indigo: {
      100: '#ebf4ff',
      200: '#c3dafe',
      300: '#a3bffa',
      400: '#7f9cf5',
      500: '#667eea',
      600: '#5a67d8',
      700: '#4c51bf',
      800: '#434190',
      900: '#3c366b'
    },
    purple: {
      100: '#faf5ff',
      200: '#e9d8fd',
      300: '#d6bcfa',
      400: '#b794f4',
      500: '#9f7aea',
      600: '#805ad5',
      700: '#6b46c1',
      800: '#553c9a',
      900: '#44337a'
    },
    pink: {
      100: '#fff5f7',
      200: '#fed7e2',
      300: '#fbb6ce',
      400: '#f687b3',
      500: '#ed64a6',
      600: '#d53f8c',
      700: '#b83280',
      800: '#97266d',
      900: '#702459'
    }
  }
};

export function createPalette(colors) {
  return {
    primary: { light: colors.primary[300], main: colors.primary[500], dark: colors.primary[700] },
    secondary: { light: colors.secondary[300], main: colors.secondary[500], dark: colors.secondary[700] },
    success: { light: colors.success[300], main: colors.success[500], dark: colors.success[700] },
    info: { light: colors.info[300], main: colors.info[500], dark: colors.info[700] },
    warning: { light: colors.warning[300], main: colors.warning[500], dark: colors.warning[700] },
    danger: { light: colors.danger[300], main: colors.danger[500], dark: colors.danger[700] }
  };
}
