import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import StackEditor from './StackEditor';
import StackViewer from './StackViewer';
import { colors } from '../style/colors';
import { flex } from '../style/style-helper';

const useStyles = makeStyles((theme) => ({
  frame: {
    ...flex.col(),
    '& a': {
      textDecoration: 'none'
    },
    // maxWidth: '24rem',
    width: '80%',
    margin: '1.5rem',
    padding: theme.spacing(2),
    backgroundColor: colors.white,
    boxShadow:
      '0px 6px 10px -3px rgba(0,0,0,0.15), 0px 10px 14px 1px rgba(0,0,0,0.10), 0px 4px 18px 3px rgba(0,0,0,0.06)',
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden'
  }
}));

const Stack = forwardRef((props, ref) => {
  const { item, onSave, isNew, onCloseEditor, onDelete } = props;
  const classes = useStyles();
  const [editing, setEditing] = useState(isNew);

  function onEdit() {
    setEditing(true);
  }

  function onCloseStackEditor() {
    setEditing(false);
    if (onCloseEditor) onCloseEditor();
  }

  function onSaveStack(stack) {
    onCloseStackEditor();
    onSave(stack);
  }

  return (
    <Card className={classes.frame} ref={ref}>
      {editing && (
        <StackEditor
          item={item}
          onClose={onCloseStackEditor}
          onSave={onSaveStack}
          onDelete={onDelete}
          showDelete={!isNew}
        />
      )}
      {!editing && <StackViewer item={item} onEdit={onEdit} />}
    </Card>
  );
});

Stack.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Stack;
