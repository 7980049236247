import React, { useState, useEffect } from 'react';
import Routes from './Routes';
import { FullPageLoading } from './components/Loading';
import Auth from './services/auth';
import history from './services/history';

function Bootstrap() {
  const [isAppReady, setAppReady] = useState(false);
  const [user, setUser] = useState(Auth.buildUserStruct());

  function authenticationCallback(authUser) {
    setUser(authUser);
    setAppReady(true);
  }

  useEffect(() => {
    Auth.configureAwsAmplifyAndRegisterCallback(authenticationCallback);
    return () => {
      Auth.unregisterAwsAmplify();
    };
  }, []); // runs once
  if (isAppReady) return <Routes user={user} history={history} />;
  return <FullPageLoading />;
}

export default Bootstrap;
