import React from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';
import App from './App';

import { SignIn, SignOut, SignInCallback, AuthenticatedRoute } from './components/Auth';
import config from './services/config';

function ShowSignLink() {
  return <Link to={config.routes.signIn}>Sign in</Link>;
}

function Routes({ user, history }) {
  return (
    <Router history={history}>
      <Switch>
        <Route path={config.routes.signIn} component={SignIn} />
        <Route path={config.routes.signOut} component={SignOut} />
        <Route path={config.routes.authenticatedCallback} render={() => <SignInCallback user={user} />} />
        <Route path={config.routes.signedOutCallback} render={() => <ShowSignLink />} />
        <AuthenticatedRoute user={user} path={config.routes.home} render={() => <App user={user} config={config} />} />
      </Switch>
    </Router>
  );
}

export default Routes;
