import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 36,
  },
  frame: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  }
}));

function Logo() {
  const classes = useStyles();
  return (
    <div className={classes.frame}>
      <a href="/">
        <img className={classes.logo} src="/link-stacks-logo.svg" alt="Link Stacks" />
      </a>
    </div>
  );
}
export default Logo;
