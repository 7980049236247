import axios from 'axios';

const pathPrefix = '';

const ApiClient = {
  signAwsRequest(request, path, credentials, config) {
    if (!credentials) {
      console.log('No credentials found for request signing!');
      return request;
    }
    const { accessToken } = credentials;
    const opts = {
      headers: { ...request.headers },
      url: `${config.apiBaseUrl}/${path}`
    };
    if (['POST', 'PATCH', 'PUT'].includes(request.method.toUpperCase())) {
      opts.body = request.body;
      if (request.data) opts.data = request.data;
    }
    const signedRequest = { ...opts, ...request };
    signedRequest.headers.Authorization = accessToken.jwtToken;
    return signedRequest;
  },

  async fetchStacks(credentials, config) {
    // If headers are added to the get request, the AWS4 signature is invalid
    // no headers and it works. I have no idea why.

    const request = {
      method: 'GET'
    };
    const signedRequest = this.signAwsRequest(request, `${pathPrefix}stacks`, credentials, config);
    const response = await axios(signedRequest);
    return response.data;
  },

  async updateStack(credentials, config, stack) {
    const request = {
      method: 'PUT',
      body: JSON.stringify(stack),
      data: stack,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };

    const signedRequest = this.signAwsRequest(request, `${pathPrefix}stacks`, credentials, config);
    const response = await axios(signedRequest);
    return response.data;
  },

  async createStack(credentials, config, stack) {
    const request = {
      method: 'POST',
      body: JSON.stringify(stack),
      data: stack,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };

    const signedRequest = this.signAwsRequest(request, `${pathPrefix}stacks`, credentials, config);
    const response = await axios(signedRequest);
    return response.data;
  },

  async deleteStack(credentials, config, stack) {
    const request = {
      method: 'DELETE'
    };

    const signedRequest = this.signAwsRequest(request, `${pathPrefix}stacks/${stack._id}`, credentials, config);
    const response = await axios(signedRequest);
    return response.data;
  }
};

export default ApiClient;
