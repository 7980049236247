import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {FiEdit3, FiExternalLink} from 'react-icons/fi';

function StackViewer({ item, onEdit }) {
  function openLinks() {
    item.links.forEach((link) => {
      window.open(link);
    });
  }

  const actionButton = (
    <>
      <IconButton edge="end" aria-label="Open Links" onClick={openLinks}>
        <FiExternalLink />
      </IconButton>
      <IconButton edge="end" aria-label="Edit" onClick={onEdit}>
        <FiEdit3 />
      </IconButton>
    </>
  );
  return (
    <>
      <CardHeader title={item.title} action={actionButton} />
      <CardContent>
        {item.links.map((link, index) => (
          <Typography key={index} variant="body1">
            {link}
          </Typography>
        ))}
      </CardContent>
    </>
  );
}

StackViewer.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired
};

export default StackViewer;
