import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FlipMove from 'react-flip-move';
import Stack from './package/Stack';
import { flex } from './style/style-helper';

function sortItems(items) {
  items.sort((a, b) => b._id.localeCompare(a._id));
}

const useStyles = makeStyles(() => ({
  frame: {
    ...flex.row(null, 'center', 'wrap')
  }
}));

function SearchItems({ items, onUpdate, onDelete }) {
  const classes = useStyles();
  if (!items) return null;
  sortItems(items);


  return (
    <FlipMove className={classes.frame}>
      {items.map((item) => (
        <Stack key={item.title} item={item} onSave={onUpdate} onDelete={onDelete}/>
      ))}
    </FlipMove>
  );
}

SearchItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SearchItems;
