import { createMuiTheme } from '@material-ui/core/styles';
import { colors, createPalette } from './colors';

const scales = [3.815, 3.052, 2.441, 1.953, 1.563, 1.25, 1, 0.875, 1, 0.875, 0.875, 0.75, 0.75];
const fonts = [
  'Inter',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
];

const defaultPalette = createPalette({
  primary: colors.custom.blue,
  success: colors.custom.green,
  info: colors.tailwind.blue,
  warning: colors.custom.orange,
  danger: colors.tailwind.red,
  secondary: colors.custom.red
});

const theme = createMuiTheme({
  typography: {
    fontFamily: fonts.join(','),
    h1: { fontSize: `${scales[0]}rem`, fontFamily: fonts.join(',') },
    h2: { fontSize: `${scales[1]}rem`, fontFamily: fonts.join(',') },
    h3: { fontSize: `${scales[2]}rem`, fontFamily: fonts.join(',') },
    h4: { fontSize: `${scales[3]}rem`, fontFamily: fonts.join(',') },
    h5: { fontSize: `${scales[4]}rem`, fontFamily: fonts.join(',') },
    h6: { fontSize: `${scales[5]}rem`, fontFamily: fonts.join(',') },
    subtitle1: { fontSize: `${scales[6]}rem`, fontFamily: fonts.join(',') },
    subtitle2: { fontSize: `${scales[7]}rem`, fontFamily: fonts.join(',') },
    body1: { fontSize: `${scales[8]}rem`, fontFamily: fonts.join(',') },
    body2: { fontSize: `${scales[9]}rem`, fontFamily: fonts.join(',') },
    button: { fontSize: `${scales[10]}rem`, fontFamily: fonts.join(',') },
    caption: { fontSize: `${scales[11]}rem`, fontFamily: fonts.join(',') },
    overline: { fontSize: `${scales[12]}rem`, fontFamily: fonts.join(',') }
  },
  shape: {
    borderRadius: 8
  },
  palette: {
    ...defaultPalette,
    text: { primary: colors.tailwind.gray[800], secondary: colors.tailwind.gray[600] }
  }
});

export default theme;
